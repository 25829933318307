
import * as React from "react"
function LogoAmazon(){
    //const path = globalHistory.location.pathname
    //width="55" height="30"
  
    return(
     
        
        <svg  viewBox="0 0 55 30" fill="none" className="logo-image" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_903_5878)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M33.7398 21.4862C30.6516 23.7604 26.1754 24.9737 22.3215 24.9737C16.9177 24.9737 12.0529 22.9769 8.37245 19.6558C8.08329 19.3946 8.34238 19.0387 8.68937 19.2421C12.6613 21.5509 17.5723 22.9399 22.6454 22.9399C26.0667 22.9399 29.8304 22.2327 33.291 20.7651C33.8138 20.5433 34.2511 21.1072 33.7398 21.4862Z" fill="#262625"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M35.2535 20.045C34.8603 19.5412 32.6441 19.807 31.6494 19.9248C31.3464 19.9618 31.3001 19.6983 31.5731 19.5088C33.3381 18.2677 36.2343 18.626 36.5721 19.042C36.9098 19.4603 36.4842 22.3608 34.8256 23.7451C34.5711 23.9578 34.3282 23.8445 34.4416 23.5626C34.814 22.6335 35.6491 20.5511 35.2535 20.045" fill="#262625"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M31.7177 10.7476V9.54115C31.7177 9.35857 31.8565 9.23608 32.0231 9.23608H37.4292C37.6027 9.23608 37.7415 9.36089 37.7415 9.54115V10.5742C37.7392 10.7476 37.5934 10.9741 37.3343 11.3323L34.533 15.3282C35.5739 15.3028 36.6727 15.4577 37.6165 15.9892C37.8294 16.1094 37.8872 16.285 37.9034 16.4584V17.7457C37.9034 17.9213 37.7091 18.127 37.5055 18.0207C35.8423 17.1494 33.6331 17.0547 31.794 18.03C31.6067 18.1316 31.41 17.9283 31.41 17.7526V16.53C31.41 16.3336 31.4123 15.9985 31.609 15.7003L34.8545 11.0503H32.03C31.8565 11.0503 31.7177 10.9278 31.7177 10.7476" fill="#262625"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.9965 18.2745H10.3518C10.1945 18.2629 10.0696 18.1451 10.058 17.9949V9.56153C10.058 9.39282 10.1991 9.25877 10.3749 9.25877H11.9086C12.0682 9.2657 12.1954 9.38819 12.207 9.54073V10.6431H12.2371C12.6373 9.5777 13.3891 9.08081 14.4023 9.08081C15.4317 9.08081 16.0748 9.5777 16.5375 10.6431C16.9353 9.5777 17.8398 9.08081 18.8091 9.08081C19.4985 9.08081 20.2526 9.36508 20.7129 10.003C21.2334 10.7125 21.127 11.7432 21.127 12.6469L21.1247 17.9694C21.1247 18.1381 20.9836 18.2745 20.8078 18.2745H19.1653C19.0011 18.2629 18.8692 18.1312 18.8692 17.9694V13.4997C18.8692 13.1438 18.9016 12.2563 18.823 11.9189C18.7004 11.3527 18.3326 11.1932 17.856 11.1932C17.4581 11.1932 17.0418 11.459 16.8729 11.8842C16.704 12.3095 16.7202 13.0213 16.7202 13.4997V17.9694C16.7202 18.1381 16.5791 18.2745 16.4033 18.2745H14.7609C14.5943 18.2629 14.4648 18.1312 14.4648 17.9694L14.4625 13.4997C14.4625 12.5591 14.6174 11.1747 13.4492 11.1747C12.2672 11.1747 12.3134 12.5244 12.3134 13.4997V17.9694C12.3134 18.1381 12.1723 18.2745 11.9965 18.2745" fill="#262625"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M42.3955 9.08081C44.836 9.08081 46.1568 11.1747 46.1568 13.8371C46.1568 16.4094 44.6972 18.4502 42.3955 18.4502C39.9989 18.4502 38.6942 16.3563 38.6942 13.747C38.6942 11.1215 40.0151 9.08081 42.3955 9.08081V9.08081ZM42.4093 10.8026C41.1972 10.8026 41.1208 12.4528 41.1208 13.4812C41.1208 14.512 41.1046 16.7122 42.3955 16.7122C43.6701 16.7122 43.7302 14.9372 43.7302 13.8556C43.7302 13.1438 43.7001 12.2933 43.485 11.6184C43.2999 11.0314 42.9321 10.8026 42.4093 10.8026" fill="#262625"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.3217 18.2745H47.6839C47.5197 18.2629 47.3878 18.1312 47.3878 17.9694L47.3855 9.53379C47.3994 9.37895 47.5359 9.25877 47.7024 9.25877H49.2269C49.3703 9.2657 49.4883 9.36277 49.5206 9.4945V10.7841H49.5507C50.0111 9.63086 50.6565 9.08081 51.7923 9.08081C52.5302 9.08081 53.2496 9.34659 53.7123 10.0746C54.1426 10.7494 54.1426 11.8842 54.1426 12.7V18.0087C54.1241 18.1566 53.9876 18.2745 53.8256 18.2745H52.1763C52.0259 18.2629 51.901 18.152 51.8848 18.0087V13.4281C51.8848 12.5059 51.9912 11.1562 50.8554 11.1562C50.4552 11.1562 50.0874 11.4243 49.9046 11.8311C49.6733 12.3464 49.6432 12.8595 49.6432 13.4281V17.9694C49.6409 18.1381 49.4975 18.2745 49.3217 18.2745" fill="#262625"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.4083 14.2465C27.4083 14.8867 27.4245 15.4206 27.1007 15.9891C26.8393 16.4513 26.4229 16.7356 25.9625 16.7356C25.331 16.7356 24.9609 16.2549 24.9609 15.5454C24.9609 14.1448 26.217 13.8906 27.4083 13.8906V14.2465V14.2465ZM29.0669 18.2517C28.9582 18.3488 28.8009 18.3557 28.6783 18.291C28.1324 17.838 28.0329 17.6277 27.7345 17.1955C26.8323 18.1154 26.1915 18.3904 25.0233 18.3904C23.6377 18.3904 22.562 17.5376 22.562 15.8296C22.562 14.4961 23.2838 13.5878 24.3155 13.1441C25.2084 12.7512 26.4553 12.6819 27.4083 12.5733V12.3606C27.4083 11.9701 27.4384 11.5078 27.2071 11.1704C27.0081 10.8676 26.6241 10.7428 26.2841 10.7428C25.6572 10.7428 25.0997 11.0641 24.9632 11.7297C24.9354 11.8776 24.8267 12.0232 24.6763 12.0301L23.0825 11.8591C22.9483 11.8291 22.798 11.7204 22.8373 11.5148C23.2028 9.58265 24.9516 9.00024 26.5154 9.00024C27.3158 9.00024 28.3614 9.21287 28.9929 9.81839C29.7933 10.5649 29.717 11.561 29.717 12.6449V15.2056C29.717 15.9752 30.0362 16.3127 30.3369 16.7287C30.441 16.8766 30.4642 17.0545 30.33 17.1655C29.9946 17.4451 29.3977 17.9651 29.0693 18.2563L29.0669 18.2517" fill="#262625"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.84631 14.2465C5.84631 14.8867 5.8625 15.4206 5.53864 15.9891C5.27724 16.4513 4.86317 16.7356 4.40051 16.7356C3.76899 16.7356 3.40118 16.2549 3.40118 15.5454C3.40118 14.1448 4.65729 13.8906 5.84631 13.8906V14.2465V14.2465ZM7.50493 18.2517C7.3962 18.3488 7.2389 18.3557 7.1163 18.291C6.57037 17.838 6.47321 17.6277 6.17248 17.1955C5.2703 18.1154 4.63184 18.3904 3.46132 18.3904C2.07798 18.3904 1 17.5376 1 15.8296C1 14.4961 1.72405 13.5878 2.75346 13.1441C3.64639 12.7512 4.89324 12.6819 5.84631 12.5733V12.3606C5.84631 11.9701 5.87638 11.5078 5.64737 11.1704C5.44611 10.8676 5.06211 10.7428 4.72437 10.7428C4.09748 10.7428 3.53766 11.0641 3.40118 11.7297C3.37342 11.8776 3.2647 12.0232 3.11665 12.0301L1.52049 11.8591C1.38632 11.8291 1.23827 11.7204 1.27528 11.5148C1.64309 9.58265 3.38961 9.00024 4.95339 9.00024C5.75378 9.00024 6.79938 9.21287 7.4309 9.81839C8.2313 10.5649 8.15496 11.561 8.15496 12.6449V15.2056C8.15496 15.9752 8.47419 16.3127 8.77492 16.7287C8.88133 16.8766 8.90446 17.0545 8.77029 17.1655C8.43486 17.4451 7.83804 17.9651 7.50955 18.2563L7.50493 18.2517" fill="#262625"/>
</g>
<defs>
<clipPath id="clip0_903_5878">
<rect width="53.1429" height="16" fill="white" transform="translate(1 9)"/>
</clipPath>
</defs>
</svg>



      
    )
  }

  
  export default LogoAmazon