
import * as React from "react"
function LogoNasa(){
    //const path = globalHistory.location.pathname
    //width="52" height="30"
  
    return(
     
        
        <svg  viewBox="0 0 52 30" fill="none" className="logo-image" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_903_5876)">
        <path d="M10.9671 18.342C11.1207 18.9308 11.3686 19.1585 11.8066 19.1585C12.2608 19.1585 12.4989 18.882 12.4989 18.342V8.31609H15.3437V18.342C15.3437 19.7552 15.1628 20.3675 14.4557 21.0842C13.9461 21.6007 13.0061 22 11.8192 22C10.8603 22 9.94276 21.6784 9.3576 21.0843C8.84458 20.5635 8.56277 20.0285 8.12237 18.3419L6.37674 11.658C6.22302 11.0692 5.97523 10.8416 5.53717 10.8416C5.08292 10.8416 4.84478 11.118 4.84478 11.658V21.684H2V11.658C2 10.2449 2.18102 9.6325 2.88812 8.91577C3.39754 8.39946 4.33761 8 5.52459 8C6.48348 8 7.40103 8.32172 7.98619 8.91567C8.49922 9.43653 8.78102 9.97151 9.22143 11.6581L10.9671 18.342Z" fill="#262625"/>
        <path d="M48.5976 21.684L45.3122 11.2692C45.276 11.1542 45.2418 11.0566 45.1891 10.9915C45.0989 10.8802 44.974 10.8217 44.8059 10.8217C44.638 10.8217 44.5131 10.8802 44.4228 10.9915C44.3702 11.0565 44.3359 11.1541 44.2997 11.2692L41.0145 21.684H38.0739L41.6625 10.3071C41.8494 9.71439 42.1167 9.21122 42.4559 8.87596C43.0379 8.30098 43.7237 8 44.806 8C45.8883 8 46.5743 8.30098 47.1561 8.87586C47.4952 9.21122 47.7625 9.71429 47.9496 10.307L51.5381 21.6839H48.5976V21.684Z" fill="#262625"/>
        <path d="M34.0586 21.684C35.9224 21.684 36.8315 21.2828 37.5497 20.559C38.3444 19.7584 38.7246 18.8825 38.7246 17.5746C38.7246 16.419 38.3071 15.3487 37.6714 14.7082C36.8403 13.8707 35.808 13.6067 34.1248 13.6067L31.7671 13.6066C30.8662 13.6066 30.5072 13.4963 30.2232 13.2135C30.0281 13.0195 29.9367 12.731 29.9367 12.3909C29.9367 12.0393 30.0202 11.6921 30.2587 11.455C30.4703 11.2445 30.7587 11.1479 31.313 11.1479H38.1269V8.31609H31.8334C29.9696 8.31609 29.0607 8.71742 28.3423 9.44107C27.5477 10.2417 27.1674 11.1175 27.1674 12.4254C27.1674 13.5812 27.5849 14.6514 28.2206 15.2918C29.0518 16.1293 30.084 16.3934 31.7672 16.3934L34.125 16.3936C35.0259 16.3936 35.3849 16.5037 35.6689 16.7866C35.864 16.9807 35.9554 17.2691 35.9554 17.6092C35.9554 17.9608 35.8719 18.308 35.6336 18.5451C35.4218 18.7556 35.1334 18.8522 34.5791 18.8522H27.5516L24.8563 10.3071C24.6693 9.71439 24.402 9.21122 24.0629 8.87596C23.4808 8.30098 22.795 8 21.7127 8C20.6304 8 19.9445 8.30098 19.3627 8.87586C19.0235 9.21122 18.7563 9.71429 18.5693 10.307L14.9807 21.6839H17.9212L21.2066 11.2691C21.2429 11.1541 21.2771 11.0565 21.3297 10.9914C21.42 10.8801 21.5449 10.8216 21.7129 10.8216C21.8809 10.8216 22.0057 10.8801 22.0961 10.9914C22.1487 11.0564 22.1829 11.154 22.2191 11.2691L25.5045 21.6839H34.0586V21.684Z" fill="#262625"/>
        </g>
        <defs>
        <clipPath id="clip0_903_5876">
        <rect width="49.5385" height="14" fill="white" transform="translate(2 8)"/>
        </clipPath>
        </defs>
        </svg>
        


      
    )
  }

  
  export default LogoNasa