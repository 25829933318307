
import * as React from "react"
function LogoJCB(){
    //const path = globalHistory.location.pathname
    //
  
    return(
     
        
        <svg  width="104" height="72" viewBox="0 0 104 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M98.0627 49.0258C98.0627 49.7211 97.4917 50.2898 96.7936 50.2898H7.20586C6.5079 50.2898 5.93677 49.7211 5.93677 49.0258V23.0037C5.93677 22.3084 6.5079 21.7396 7.20586 21.7396H96.7936C97.4917 21.7396 98.0627 22.3084 98.0627 23.0037V49.0258Z" fill="#262526"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M29.859 45.5729C28.3136 47.0506 26.0261 47.3888 22.6571 47.3888H17.8665C11.1592 47.3888 9.02661 45.8191 9.02661 40.4026V37.4477H15.4555C15.4555 37.7249 15.4246 38.0017 15.4246 38.248C15.4246 40.9564 16.1045 41.8178 18.7937 41.8178H21.8846C24.3572 41.8178 24.7898 40.8947 24.7898 38.6479V24.7675H31.6515V40.8331C31.6515 42.7721 30.9407 44.5266 29.859 45.5729Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M56.6935 32.6156C56.6935 30.8309 55.55 30.092 53.0773 30.092H47.5757C43.1248 30.092 42.043 30.6768 42.043 34.4932V37.6017C42.043 41.1106 43.5575 41.8179 47.5757 41.8179H51.5629C55.3644 41.8179 56.8791 41.4184 56.8791 38.7407C56.8791 38.648 56.8791 38.5866 56.8791 38.4941H63.8332C63.8643 39.0791 63.8952 39.6023 63.8952 40.1256C63.8952 42.8952 63.2463 44.7418 61.6391 45.9119C59.939 47.1733 57.2499 47.4195 53.3243 47.4195H45.6594C42.1049 47.4195 39.6014 47.2659 37.6541 45.7575C35.7687 44.2804 35.0579 42.2492 35.0579 39.2018V33.1083C35.0579 26.3987 37.9941 24.4906 45.6594 24.4906H53.3243C57.2499 24.4906 59.9081 24.6752 61.5462 25.9371C62.9988 27.045 63.617 28.8919 63.617 31.5694C63.617 31.9694 63.617 32.4002 63.5859 32.8312H56.6935C56.6935 32.77 56.6935 32.6774 56.6935 32.6156Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M88.2744 40.0021C88.2744 38.8023 87.5324 38.0325 86.3893 38.0325H73.7167V42.0644H86.3893C87.5944 42.0644 88.2744 41.3257 88.2744 40.0021ZM87.5324 31.6308C87.5324 30.5229 86.8219 29.7844 85.7709 29.7844H73.7167V33.4775H85.7709C86.9146 33.4775 87.5324 32.8311 87.5324 31.6308ZM66.8547 47.1427V24.7675H87.1001C92.138 24.7675 94.5798 26.7988 94.5798 31.0152C94.5798 33.4159 93.5291 34.8931 91.2726 35.7552C94.1163 36.2167 95.3525 37.8785 95.3525 41.0798C95.3525 45.3885 93.1269 47.1427 88.1507 47.1427H66.8547Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.00684 33.9671H18.2435V24.7694H9.00684V33.9671Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.718 27.6727C14.718 27.6727 15.5448 27.7909 15.622 27.8031C15.9665 27.8579 16.116 28.1345 16.1583 28.264C16.1724 28.3067 16.2681 28.6074 16.2805 28.6405C16.3744 28.8939 16.1363 29.0247 16.1363 29.0247C16.1363 29.0247 16.4083 29.0743 16.5006 29.292C16.5006 29.292 16.6726 29.7936 16.6898 29.8447C16.7067 29.8955 16.8297 30.296 16.4105 30.4431C16.3544 30.4628 15.6797 30.6418 15.6797 30.6418L14.718 27.6727ZM15.4115 28.2891C15.4115 28.2891 15.6084 28.3035 15.6571 28.3075C15.7056 28.3117 15.7395 28.3337 15.7589 28.3759C15.7712 28.4022 15.845 28.6175 15.8534 28.6409C15.8617 28.6644 15.8991 28.7937 15.7904 28.7965C15.7735 28.7969 15.5779 28.7965 15.5779 28.7965L15.4115 28.2891ZM15.7548 29.3661C15.7548 29.3661 15.9068 29.3602 15.9497 29.3576C15.9929 29.3547 16.0739 29.3506 16.1233 29.4452C16.136 29.4696 16.2126 29.704 16.2224 29.7316C16.2321 29.7593 16.2543 29.8863 16.1538 29.9084C16.1353 29.9124 15.9568 29.9471 15.9568 29.9471L15.7548 29.3661Z" fill="#262526"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.375 27.1732L12.7297 27.3757L12.9487 28.1102L12.6325 28.0831C12.6325 28.0831 13.4112 30.5676 13.4381 30.6447C13.5357 30.9246 13.5951 31.2145 13.169 31.3544C13.1132 31.3728 12.7166 31.4867 12.6681 31.4978C12.4221 31.5552 12.1089 31.5564 11.9877 31.1751L11.4923 29.639L12.101 29.5964C12.101 29.5964 12.3525 30.3852 12.372 30.4434C12.4276 30.6083 12.4617 30.6715 12.6348 30.6315C12.762 30.6021 12.7568 30.5513 12.7234 30.4423C12.7092 30.3964 11.9765 28.0318 11.9765 28.0318L11.6302 28.0059L11.375 27.1732Z" fill="#262526"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.3954 27.466C13.4546 27.4755 13.8902 27.544 13.9639 27.5559C14.0374 27.568 14.4429 27.6339 14.5503 28.006C14.5768 28.0977 14.7908 28.7519 14.7908 28.7519L14.2762 28.747C14.2762 28.747 14.1806 28.4306 14.1602 28.3761C14.1105 28.2427 14.1076 28.2083 13.9529 28.1853C13.7966 28.1674 13.8049 28.2326 13.8397 28.3424C13.8576 28.399 14.3256 29.942 14.3465 30.0082C14.4016 30.1834 14.4153 30.2532 14.5802 30.2213C14.7234 30.188 14.6962 30.1119 14.6597 29.9827C14.6471 29.9391 14.5002 29.4771 14.5002 29.4771L15.0113 29.4392C15.0113 29.4392 15.2717 30.2588 15.2854 30.2971C15.299 30.3355 15.408 30.6674 15.0986 30.8102C15.0588 30.8286 15.0588 30.8315 15.0179 30.845C14.9769 30.8585 14.5629 30.9741 14.5235 30.9847C14.4842 30.9954 14.09 31.1156 13.9619 30.7286C13.9494 30.6915 13.1161 27.9723 13.0719 27.8394C12.9967 27.6138 13.0766 27.4207 13.3954 27.466Z" fill="#262526"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.7104 26.5218C10.8223 26.5385 15.645 27.3313 15.7679 27.351C15.892 27.3712 16.3668 27.4218 16.5566 27.9615C16.5933 28.0651 17.1933 29.863 17.2221 29.9526C17.3281 30.2851 17.2826 30.6322 16.843 30.7781C16.6979 30.8266 12.5594 32.1352 12.5084 32.1513C12.3947 32.1877 11.7868 32.4559 11.5656 31.7561C11.5656 31.7561 10.2259 27.5608 10.186 27.4339C10.1241 27.2371 10.0853 27.0702 10.0852 26.9471C10.0848 26.402 10.6027 26.5107 10.7104 26.5218ZM10.8258 26.7451C11.0803 26.7867 15.4974 27.503 15.6937 27.5355C15.89 27.568 16.2198 27.6146 16.358 28.0233C16.3849 28.103 16.9843 29.8886 17.0084 29.966C17.0323 30.0434 17.2068 30.4512 16.7145 30.6033C16.6413 30.626 12.4885 31.9163 12.3878 31.9466C12.2871 31.9768 11.8945 32.1405 11.7332 31.5759C11.7332 31.5759 10.4652 27.5738 10.4069 27.3935C10.3594 27.2467 10.3253 27.1289 10.318 27.0375C10.2931 26.7089 10.4974 26.6985 10.6654 26.7211C10.7286 26.7294 10.7852 26.7389 10.8258 26.7451Z" fill="#262526"/>
</svg>

        


      
    )
  }

  
  export default LogoJCB