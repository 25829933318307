
import * as React from "react"
function LogoGoodYear(){
    //const path = globalHistory.location.pathname
    //width="52" height="30"
  
    return(
     
      <svg width="164" height="72" viewBox="0 0 164 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.4826 42.9308H11.9862C9.91493 42.9308 8 41.2113 8 37.9677C8 31.0505 13.7057 29.0183 17.3792 29.0183C20.0758 29.0183 21.4436 30.0344 21.4436 30.0344L20.4275 31.8712C20.4275 31.8712 19.724 31.0114 18.1218 30.816C16.793 30.6597 15.8942 31.2459 15.7379 31.7148L13.5103 39.9217C13.354 40.4688 13.5494 41.2504 14.0965 41.2504H15.8551L17.5356 35.5838H23.3194L21.4826 42.9308Z" fill="white"/>
<path d="M34.6919 28.8621C30.4712 28.8621 25.5862 31.8712 25.5862 37.4988C25.5862 41.7585 29.2988 43.3217 31.8781 43.3217C37.2321 43.3217 40.8665 38.2022 40.8665 34.3724C40.8665 30.5425 37.2321 28.8621 34.6919 28.8621ZM34.4574 30.777C35.747 30.9333 35.3562 32.4184 35.3562 32.4184L33.3241 40.5079C33.3241 40.5079 33.0505 41.8367 31.6827 41.6022C30.5103 41.4068 30.7838 40.0781 30.7838 40.0781L32.9332 31.9885C32.8942 31.9885 33.285 30.6598 34.4574 30.777Z" fill="white"/>
<path d="M71.7005 29.2137L62.6339 29.2919L59.1167 42.9699H68.4959C70.1764 42.9699 75.335 40.5861 75.5694 34.5286C75.6867 30.3471 72.4821 29.2137 71.7005 29.2137ZM67.8706 31.2068H69.5902C70.1373 31.3631 70.2936 32.1448 70.0982 32.731L68.1833 40.3125C68.1051 40.6642 67.5971 40.9769 67.1672 40.9769H65.3304L67.8706 31.2068Z" fill="white"/>
<path d="M93.1164 43.048H99.5646L101.128 36.8734L107.537 29.1355H104.684L100.268 34.1378L98.6267 29.1355H92.1003L94.5624 36.8734L93.1164 43.048Z" fill="white"/>
<path d="M109.061 29.1747L105.388 42.97H116.721L117.19 40.8596H112.149L113.165 37.2252H116.604L116.994 34.8804H113.868L114.689 31.4413H119.808L120.16 29.1747H109.061Z" fill="white"/>
<path d="M126.491 29.1747L118.089 42.97H120.59L121.84 40.8987H127.077L127.116 42.97H133.134L131.923 29.1747H126.491ZM126.178 33.9425L126.803 38.7493H123.169L126.178 33.9425Z" fill="white"/>
<path d="M138.645 29.1747L134.971 43.0091H141.146L142.279 38.9838H143.1L144.116 43.0091H150.408L149.314 38.085C149.314 38.085 152.597 36.17 152.597 33.3172C152.597 30.3862 149.861 29.1747 148.259 29.1747H138.645ZM146.109 31.1287C146.578 31.1287 146.617 32.3011 146.461 32.8873L145.758 35.9355C145.64 36.4436 145.015 36.9126 144.663 36.8344H142.905L144.194 31.1287H146.109Z" fill="white"/>
<path d="M79.9856 36.2091L82.4476 41.2895C82.4476 41.2895 80.1028 43.6343 79.0867 44.6504C76.4293 47.3469 74.3971 47.5423 74.1236 47.3469C73.381 46.8389 75.9603 45.0021 76.8201 43.3217C77.7189 41.6803 78.2661 38.3976 79.9856 36.2091Z" fill="white"/>
<path d="M80.689 35.3494L83.3855 40.4689C83.3855 40.4689 85.8085 38.3194 89.0912 36.3264L88.0751 34.4896C88.0751 34.4896 86.8245 36.131 86.551 36.131C85.8866 36.131 84.5579 33.4735 84.5579 33.4735L80.689 35.3494Z" fill="white"/>
<path d="M83.2293 32.7309L80.4937 34.2551L80.5718 32.262L83.2293 32.7309Z" fill="white"/>
<path d="M84.4015 29.9562C84.3625 30.1516 84.2452 31.4803 84.2452 31.4803L81.8613 31.4412L84.4015 29.9562Z" fill="white"/>
<path d="M78.657 29.0183L79.9857 31.1677L83.8546 28.8229L83.9719 26.0482L78.657 29.0183Z" fill="white"/>
<path d="M74.9443 48.4802C75.0224 48.2457 76.5856 47.9331 78.8132 46.1745C80.2201 45.0803 84.1672 41.1332 84.1672 41.1332C84.1672 41.1332 87.2545 38.3585 89.5603 37.147C89.7557 37.0297 90.0292 37.5769 89.8338 37.6941C86.7465 39.3355 85.8086 40.6251 84.1672 41.9929C82.5258 43.3607 80.7281 45.6274 79.0086 46.917C77.9535 47.6986 75.5305 49.1446 75.1397 48.9883C74.9443 48.9492 74.9052 48.7147 74.9443 48.4802Z" fill="white"/>
<path d="M104.723 23C104.45 23 102.144 23.3908 101.128 23.4299C99.5258 23.508 97.3764 23.469 95.7741 23.4299C93.8983 23.3908 89.9121 23.1563 89.9121 23.1563C86.434 23.2345 85.0271 25.3057 85.0271 27.1034C85.0271 28.7057 85.6915 29.0183 85.6915 29.4091C85.6915 30.0344 85.457 30.777 85.5351 31.3241C85.6524 32.2229 85.8478 34.1378 86.6294 34.1378C87.411 34.1378 87.6455 31.5195 88.1926 31.5195C88.5052 31.5195 88.5443 32.1838 89.0133 32.1838C89.365 32.1838 89.3259 30.3862 89.5213 30.3862C89.834 30.3862 90.1466 30.8942 90.3811 30.8942C90.811 30.8942 90.9282 28.0023 91.1627 28.0023C91.8661 28.0023 92.5696 28.7839 93.8983 27.9632C94.1328 27.8069 93.4293 27.6115 93.4684 27.3379C93.5466 26.9471 95.5006 27.377 95.7351 26.7908C95.9305 26.3609 94.3282 26.2437 94.7581 25.5011C94.7971 25.423 96.4776 25.7747 97.2201 25.8529C98.1971 25.931 100.542 25.8529 100.542 25.8529C103.707 25.7747 104.137 24.2115 104.723 23C104.723 23 104.763 23 104.723 23ZM90.3811 24.2896C90.811 24.3287 91.8661 24.3678 91.8661 24.3678C91.8661 24.3678 91.9443 25.0322 91.3581 25.0322C90.6156 25.0322 90.5765 25.8919 90.0684 25.8919C89.1305 25.8919 89.2087 26.3218 88.8179 26.4C88.4661 26.4781 87.5673 26.4 87.5673 26.4C87.411 26.3218 87.2156 26.0873 87.2156 25.8919C87.2156 25.7747 87.5673 25.6965 88.1144 25.6965C88.6616 25.6965 87.9581 24.8758 89.6386 24.8758C90.0294 24.8758 90.1857 24.2896 90.3811 24.2896ZM86.2777 27.7287C86.6294 27.7287 87.0983 27.8069 87.2547 28.0023C87.5282 28.3149 87.6064 29.4091 87.1765 29.4091C86.7857 29.4091 86.7857 28.8229 86.5903 28.6275C86.3949 28.3931 85.7305 28.1977 85.8478 27.9241C85.9259 27.7678 86.0823 27.7287 86.2777 27.7287Z" fill="white"/>
<path d="M52.1606 28.8621C47.94 28.8621 43.0549 31.8712 43.0549 37.4988C43.0549 41.7585 46.7675 43.3217 49.3468 43.3217C54.7008 43.3217 58.3353 38.2022 58.3353 34.3724C58.3353 30.5425 54.7008 28.8621 52.1606 28.8621ZM51.9261 30.777C53.2158 30.9333 52.825 32.4184 52.825 32.4184L50.7928 40.5079C50.7928 40.5079 50.5192 41.8367 49.1514 41.6022C47.979 41.4068 48.2526 40.0781 48.2526 40.0781L50.402 31.9885C50.3238 31.9885 50.7537 30.6598 51.9261 30.777Z" fill="white"/>
</svg>

      
    )
  }

  
  export default LogoGoodYear