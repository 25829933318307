
import * as React from "react"
function LogoVodafone(){
    //const path = globalHistory.location.pathname
    //width="52" height="30"
  
    return(
     
        
        <svg width="82" height="72" viewBox="0 0 82 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_906_5655)">
        <path d="M14.0152 53.394L15.9464 58.3237L17.8147 53.394H19.7459L16.7546 60.8203H15.0963L12 53.394H14.0152Z" fill="white"/>
        <path d="M20.3527 55.0019C20.6885 54.3672 21.1399 53.9017 21.6856 53.6267C22.2314 53.3516 22.8087 53.2141 23.3965 53.2141C24.2256 53.2141 24.9184 53.4363 25.4851 53.8911C26.0519 54.3355 26.4298 54.8538 26.6187 55.4674C26.8181 56.1021 26.9231 56.6416 26.9231 57.0859C26.9231 57.837 26.7551 58.5352 26.4193 59.1911C26.1674 59.6883 25.8315 60.0903 25.4222 60.3759C25.0128 60.6615 24.6245 60.8414 24.2676 60.9154C23.9108 60.9789 23.6169 61.0212 23.3965 61.0212C22.8087 61.0212 22.2839 60.9048 21.8431 60.6827C21.4023 60.4605 21.0349 60.1537 20.741 59.7835C20.4471 59.4132 20.2267 59.0007 20.0798 58.5352C19.9328 58.0697 19.8594 57.5937 19.8594 57.1071C19.8594 56.3242 20.0273 55.6366 20.3527 55.0019ZM21.8326 57.9639C21.9061 58.239 22.011 58.4823 22.1475 58.6833C22.2839 58.8843 22.4623 59.0536 22.6723 59.1699C22.8822 59.2863 23.1236 59.3498 23.3965 59.3498H23.4909C23.7848 59.3498 24.0262 59.2757 24.2256 59.117C24.4461 58.9372 24.614 58.7256 24.7504 58.4823C24.8764 58.239 24.9708 57.9851 25.0233 57.7206C25.0548 57.5302 25.0758 57.3715 25.0758 57.234C25.0758 57.1811 25.0758 57.1282 25.0653 57.0859C25.0653 56.5252 24.9499 56.0598 24.7189 55.6789C24.488 55.2981 24.2466 55.0654 23.9842 54.9596C23.7428 54.8644 23.5644 54.8221 23.428 54.8327H23.386C22.9556 54.8327 22.5988 54.9913 22.3154 55.2981C22.0845 55.552 21.9271 55.8376 21.8431 56.155C21.7591 56.4724 21.7171 56.7791 21.7171 57.0859C21.7171 57.3927 21.7591 57.6889 21.8326 57.9639Z" fill="white"/>
        <path d="M28.3422 55.0019C28.5731 54.5152 28.8985 54.145 29.3183 53.8699C29.7381 53.5949 30.1265 53.4151 30.4938 53.3304C30.8297 53.2458 31.1026 53.2141 31.3125 53.2141H31.3755C31.7324 53.2141 32.1522 53.2881 32.635 53.4362V50.5482H34.5138V60.8307H32.7819L32.7085 60.2595C32.53 60.4605 32.2991 60.6297 32.0157 60.7778C31.7324 60.9259 31.3965 61 31.0081 61C30.5043 61 30.053 60.8942 29.6647 60.6721C29.2763 60.4499 28.951 60.1643 28.6886 59.8046C28.4262 59.4449 28.2267 59.0218 28.0903 58.5563C27.9538 58.0803 27.8804 57.5936 27.8804 57.0753C27.8699 56.2819 28.0273 55.5943 28.3422 55.0019ZM29.8221 57.9216C29.8851 58.2072 29.99 58.4505 30.1265 58.6621C30.2629 58.8737 30.4414 59.0429 30.6513 59.1699C30.8612 59.2968 31.1131 59.3603 31.3965 59.3603C31.6274 59.3603 31.8688 59.2968 32.1102 59.1699C32.3516 59.0429 32.53 58.8948 32.635 58.715V55.0442C32.2362 54.8961 31.8898 54.822 31.6064 54.822C31.0816 54.822 30.6513 54.9913 30.3259 55.3404C30.011 55.6789 29.8221 56.0174 29.7696 56.3454C29.7276 56.5781 29.7067 56.7685 29.7067 56.906C29.7067 56.9483 29.7067 56.9907 29.7171 57.0224C29.7171 57.3398 29.7486 57.636 29.8221 57.9216Z" fill="white"/>
        <path d="M35.8159 57.5939C35.9838 57.2977 36.2042 57.0543 36.4771 56.8534C36.75 56.6524 37.0439 56.4937 37.3483 56.3879C37.6526 56.2821 37.9465 56.2081 38.1984 56.1763C38.3454 56.1552 38.8492 56.0917 39.7308 55.9859V55.8801C39.7308 55.4675 39.6259 55.1713 39.4159 55.0021C39.206 54.8328 38.9226 54.7376 38.5448 54.7376C38.1774 54.7376 37.8206 54.8222 37.4847 54.9809C37.1488 55.1396 36.8969 55.2983 36.7185 55.4464L36.0888 54.1029C36.2987 53.8913 36.6975 53.6797 37.2853 53.4893C37.894 53.2883 38.4188 53.1931 38.8597 53.1931C39.332 53.1931 39.7413 53.2672 40.0877 53.4153C40.434 53.5634 40.7279 53.7644 40.9588 54.0288C41.1897 54.2933 41.3577 54.5895 41.4626 54.9386C41.5676 55.2877 41.6201 55.6474 41.6201 56.0388V60.8204H39.8673L39.7938 60.2597C39.5944 60.4819 39.332 60.6617 39.0171 60.7992C38.7022 60.9262 38.3559 61.0002 37.978 61.0002C37.6631 61.0002 37.3588 60.9473 37.0754 60.8415C36.7815 60.7357 36.5296 60.5771 36.2987 60.3761C36.0783 60.1751 35.8893 59.9318 35.7529 59.6356C35.6164 59.3394 35.543 59.0114 35.543 58.6412C35.564 58.2392 35.6479 57.8901 35.8159 57.5939ZM37.6841 59.1912C37.8626 59.3394 38.1145 59.4134 38.4503 59.4134C38.6917 59.4134 38.9331 59.3605 39.164 59.2547C39.3949 59.1489 39.5944 59.022 39.7518 58.8633V57.3506C39.1326 57.4246 38.7127 57.4775 38.5133 57.5092C38.104 57.5939 37.8206 57.7314 37.6631 57.9324C37.5057 58.1334 37.4217 58.3555 37.4217 58.5883C37.4112 58.8421 37.4952 59.0326 37.6841 59.1912Z" fill="white"/>
        <path d="M42.6375 53.3939H43.6136V52.9919C43.6136 52.2726 43.7605 51.7013 44.0544 51.2782C44.3378 50.8445 44.6736 50.5906 45.062 50.4954C45.4293 50.4001 45.7547 50.3472 46.0381 50.3367C46.4999 50.3367 46.8778 50.379 47.1822 50.4636C47.4865 50.5482 47.665 50.6117 47.7174 50.6435L47.497 52.2303C47.4341 52.1985 47.3186 52.1562 47.1402 52.1033C46.9617 52.0504 46.7203 52.0187 46.4265 52.0187C46.1641 52.0187 45.9436 52.0927 45.7652 52.2514C45.5868 52.4101 45.4923 52.664 45.4923 53.0342V53.3833H47.0877V54.9278H45.5133V60.8202H43.6136V54.9278H42.6375V53.3939V53.3939Z" fill="white"/>
        <path d="M47.9384 55.0018C48.2742 54.3671 48.7256 53.9016 49.2713 53.6266C49.8171 53.3515 50.3944 53.214 50.9822 53.214C51.8113 53.214 52.5041 53.4361 53.0708 53.891C53.6376 54.3353 54.0155 54.8537 54.2044 55.4673C54.4038 56.102 54.5088 56.6415 54.5088 57.0858C54.5088 57.8369 54.3408 58.5351 54.005 59.191C53.7531 59.6882 53.4172 60.0901 53.0079 60.3758C52.5985 60.6614 52.2102 60.8412 51.8533 60.9153C51.4965 60.9788 51.2026 61.0211 50.9822 61.0211C50.3944 61.0211 49.8696 60.9047 49.4288 60.6826C48.988 60.4604 48.6206 60.1536 48.3267 59.7834C48.0328 59.4131 47.8124 59.0005 47.6655 58.5351C47.5185 58.0696 47.4451 57.5936 47.4451 57.107C47.4556 56.3241 47.613 55.6365 47.9384 55.0018ZM49.4288 57.9638C49.5023 58.2389 49.6072 58.4822 49.7437 58.6832C49.8801 58.8842 50.0585 59.0534 50.2684 59.1698C50.4784 59.2862 50.7198 59.3496 50.9927 59.3496H51.0871C51.381 59.3496 51.6224 59.2756 51.8218 59.1169C52.0422 58.9371 52.2102 58.7255 52.3466 58.4822C52.4831 58.2389 52.567 57.985 52.6195 57.7205C52.651 57.5301 52.672 57.3714 52.672 57.2339C52.672 57.181 52.672 57.1281 52.6615 57.0858C52.6615 56.5251 52.546 56.0597 52.3151 55.6788C52.0842 55.298 51.8428 55.0653 51.5804 54.9595C51.339 54.8643 51.1606 54.822 51.0241 54.8325H50.9822C50.5518 54.8325 50.195 54.9912 49.9116 55.298C49.6807 55.5519 49.5232 55.8375 49.4393 56.1549C49.3553 56.4722 49.3133 56.779 49.3133 57.0858C49.3133 57.3926 49.3553 57.6888 49.4288 57.9638Z" fill="white"/>
        <path d="M55.6108 53.394H57.3322L57.4266 53.9864C57.7205 53.743 58.0564 53.5526 58.4237 53.4151C58.8016 53.2776 59.1689 53.2141 59.5468 53.2141C60.1345 53.2141 60.6488 53.3516 61.0582 53.6161C61.478 53.8806 61.7719 54.2826 61.9398 54.8009C62.1078 55.3193 62.1917 55.8694 62.1917 56.4406V60.8414H60.292V56.5993C60.292 56.0281 60.1765 55.5943 59.9456 55.2875C59.7147 54.9808 59.3893 54.8221 58.959 54.8221C58.6651 54.8221 58.3922 54.8961 58.1403 55.0548C57.8884 55.2135 57.668 55.3828 57.4896 55.5626V60.8308H55.5898V53.394H55.6108Z" fill="white"/>
        <path d="M63.5776 55.4461C63.756 54.9594 63.9974 54.5574 64.3123 54.2295C64.6272 53.9016 64.984 53.6477 65.4039 53.4678C65.8132 53.288 66.254 53.2034 66.7263 53.2034C67.5555 53.2034 68.2272 53.4361 68.752 53.891C69.2768 54.3564 69.6232 54.896 69.7701 55.5307C69.9276 56.1443 70.001 56.7155 70.001 57.2444V57.583H65.2254C65.2674 58.2071 65.4563 58.662 65.8027 58.937C66.1491 59.2121 66.6004 59.3496 67.1462 59.3496C67.629 59.3496 68.0593 59.2756 68.4267 59.1169C68.794 58.9688 69.0459 58.8207 69.1824 58.6831L69.6862 60.2382C69.4657 60.4075 69.2453 60.5238 69.0459 60.5873C68.92 60.6508 68.6156 60.7248 68.1433 60.82C67.671 60.9258 67.2616 60.9787 66.8838 60.9787C66.401 60.9787 65.9496 60.9047 65.5088 60.746C65.068 60.5873 64.6901 60.3546 64.3648 60.0266C64.0394 59.6987 63.777 59.2967 63.5881 58.8207C63.3992 58.334 63.3047 57.7628 63.3047 57.0858C63.3152 56.4828 63.3992 55.9221 63.5776 55.4461ZM68.0698 56.2183C68.0383 55.7952 67.9229 55.4778 67.7024 55.2451C67.4925 55.0229 67.2931 54.8748 67.0937 54.8219C66.9048 54.769 66.7788 54.7373 66.7053 54.7373C66.38 54.7373 66.0651 54.8642 65.7712 55.1075C65.4773 55.3508 65.3094 55.7211 65.2569 56.2183H68.0698Z" fill="white"/>
        <path d="M49.0188 11.7561C45.7651 12.2215 43.2461 15.2788 43.1832 19.0025C47.6859 19.8382 51.0865 22.8849 51.0865 28.3223C51.0865 33.5694 46.7622 37.8114 41.3569 37.8114C35.983 37.822 31.6272 33.0616 31.6272 26.8942C31.6272 18.3889 39.1213 11.3329 48.599 11.5551C46.3844 10.5607 43.9389 10 41.3569 10C31.5957 10 23.6714 17.9764 23.6714 27.8251C23.6714 37.6739 31.5957 45.6503 41.3569 45.6503C51.118 45.6503 59.0424 37.6739 59.0424 27.8251C59.0424 20.748 54.949 14.6335 49.0188 11.7561Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_906_5655">
        <rect width="58" height="51" fill="white" transform="translate(12 10)"/>
        </clipPath>
        </defs>
        </svg>
        


      
    )
  }

  
  export default LogoVodafone