import * as React from "react"
// import { Link } from "gatsby"
//  import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"



// import vodafoneLogo from "../images/logo-vodafone.png"
// import jcbLogo from "../images/logo-jcb.png"
//import goodyearLogo from "../images/logo-goodyear.png"
//import scaniaLogo from "../images/logo-scania.png"
//import rollsroyceLogo from "../images/logo-rollsroyce.png"


import twitter from "../images/svg/social-twitter.svg"
import linkedin from "../images/svg/social-linkedin.svg"
import email from "../images/svg/email-sml.svg"

import NasaLogo from "../components/logos/logo-nasa";
import AmazonLogo from "../components/logos/logo-amazon";
import WhoLogo from "../components/logos/logo-who";

import JCBLogo from "../components/logos/logo-jcb";
import VodafoneLogo from "../components/logos/logo-vodafone";
import RollsRoyceLogo from "../components/logos/logo-rolls-royce";
import GoodYearLogo from "../components/logos/logo-goodyear";
import ScaniaLogo from "../components/logos/logo-scania";

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />


    <section id="about-me" className="martin-fisher">

      <div className="section-content section-content--prime">

        <h1 className="text-shadow--home">Hi I’m Martin.<br></br>I help big brands create brilliant accessible products.</h1>
        <p className="large pad-top-sm">
          I’m currently working on a suite of SaaS products used by NASA, WHO and Amazon.
        </p>
        <div className="client-logos">
          <ul className="inline small-stack">
            <li><NasaLogo /></li>
            <li><WhoLogo /></li>
            <li><AmazonLogo /></li>

            {/* <li>

              <img src={nasaLogo} alt="NASA Logo" className="logo-image" />
            </li> */}

          </ul>
        </div>
      </div>
    </section>


    <section id="history">
      <div className="history-wrapper">
        <div className="KPIs">
          <div className="previous-clients">
            <span className="micro-title small-print">Completed web and native mobile applications for:</span>
            <ul className="inline brands">
              {/* <li><img src={vodafoneLogo} alt="Vodafone Logo" className="logo-image-client" /></li> */}
              <li><VodafoneLogo /></li>
              {/* <li><img src={jcbLogo} alt="JCB Logo" className="logo-image-client" /></li> */}
              <li><JCBLogo /></li>
              {/* <li className="hide-on-small"><img src={goodyearLogo} alt="Goodyear Logo" className="logo-image-client" /></li> */}
              <li className="hide-on-small"><GoodYearLogo /></li>
              {/* <li className="hide-on-small"><img src={scaniaLogo} alt="Scania Logo" className="logo-image-client" /></li> */}
              <li className="hide-on-small"><ScaniaLogo /></li>
              {/* <li><img src={rollsroyceLogo} alt="Rolls-Royce Logo" className="logo-image-client" /></li> */}
              <li><RollsRoyceLogo /></li>
            </ul>
          </div>
          <div className="experience">
            <div className="industry-experience">
              <span className="micro-title small-print">Years experience:</span>
              <span className="large-number">21+</span>
            </div>
            <div className="previous-roles">
              <span className="micro-title small-print">Previous roles:</span>
              <ul className="basic">
                <li>Creative Director</li>
                <li>Head of Design</li>
                <li>Senior UX Consultant</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="section-content">
          <div className="row-wrapper">
            <div className="col-50">
              <h2 className="mt-0">UX Designer with development skills</h2>
              <p>Through the years, I've been a self-taught programmer, database designer and frontend developer. I've designed and built games for film launches, web and mobile applications using a wealth of technologies and languages.</p>

            </div>
            <div className="col-50">
              <p>With an educational background in Product Design, I've used these fundamental disciplines throughout my digital career. I've worked with large corporations and household names, with development teams that span languages and continents.</p>
              <p>More recently, I've trained in Design Sprints and cemented my knowledge of Accessibility through professional IAAP membership.  Currently studying for my Web Accessibility Specialist qualification, I understand the role of accessibility within the web and mobile space.</p>
              <p>A keen lifelong learner, I'm always willing to discover new industries and problem-solving techniques, from mobile to manufacturing, fintech to furniture. I've worked with most, but there's always space to learn something new.</p>
              <p>
                <br></br>
                <a href="#contact" className="CTA" >Get in touch</a>
              </p>

            </div>

          </div>
        </div>

      </div>
    </section>


    {/* <section id="work" className="section-inverse">
      <div className="section-content work-content">
        <span className="section-title text-brandprimary">Examples</span>
        <h2>Work</h2>
        <p className="">Here's a selection of recent branding work, mobile applications, websites and web apps.</p>
        <div className="work-examples">
          <div className="work-overview">
            <h4>Blend Beauty</h4>
            <p className="">
              Branding logo and art direction for a website specialising in foundation blends for multiple skin types and colours. The logo reflects a makeup colour palette with the overlapping elements adding to the depth and range of natural skin tones.
            </p>

          </div>
          <div className="work-visuals"><p>&nbsp;</p></div>
        </div>

      </div> 
    </section>*/}

    <section id="work2" className="section-inverse">
      <div class="section_main-1">
        <div class="section_container-6">
          <span className="section-title text-brandprimary">Examples</span>
          <h2>Work</h2>

          <div class="section_wrap-main">
            <div class="section_wrap-1">
              <h4>Blend Beauty</h4>
              <p class="">Branding, logo and art direction for a website specialising in foundation blends for multiple skin types and colours.</p>
            </div>
            <div class="section-wrap-2">
              <div class="section_screen-wrap-2">
                <img src="https://assets.codepen.io/298070/beauty-blend-mac.jpg" alt="" class="hero_img-1" />
              </div>
              <div class="hero_phone-wrap-main">
                <div class="hero-image-2">
                  <img src="https://assets.codepen.io/298070/beauty-blend-mobile.jpg" alt="" class="hero_img-2" />
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section id="contact" className="bg-footer">
      <div className="contact-block">
        <div>
          <p className="small-print">CONTACT</p>
          <h3>Get in touch</h3>
          <p className="large">Got a project you want to discuss?<br></br> Choose your method</p>
          <ul className="inline social">
            <li><a href="https://twitter.com/humaanUX" className="social-link"><img src={twitter} alt="Twitter" /></a></li>
            <li><a href="https://www.linkedin.com/in/martin-fisher/" className="social-link"><img src={linkedin} alt="Linkedin" /></a></li>
            <li><a href="mailto:hello@humaan.co.uk?subject=Contact from website" className="social-link"><img src={email} alt="Email" /></a></li>
          </ul>
          <p className="small-print">Built with Gatsby <span role="img" aria-label="raising hands emoji">🙌</span></p>
        </div>
      </div>
    </section>

  </Layout>
)

export default IndexPage
