
import * as React from "react"
function LogoRollsRoyce(){
    //const path = globalHistory.location.pathname
    //width="52" height="30"
  
    return(
     
        <svg width="63" height="72" viewBox="0 0 63 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_906_5710)">
        <path d="M14.116 10.2212C14.116 8.22378 15.7172 6.59924 17.686 6.59924H45.1965C47.1915 6.59924 48.7928 8.22378 48.7928 10.2212V62.7789C48.7928 64.7763 47.1915 66.4008 45.1965 66.4008H17.686C15.7172 66.4008 14.116 64.7763 14.116 62.7789V10.2212V10.2212Z" fill="white"/>
        <path d="M15.1392 16.4129V10.2077C15.1392 9.03588 16.1104 8.09045 17.2917 8.09045H45.7078C46.9284 8.09045 47.8997 9.03588 47.8997 10.2077V16.4129H15.1392Z" fill="#262526"/>
        <path d="M15.1392 56.5736V62.7788C15.1392 63.9506 16.1104 64.896 17.2917 64.896H45.7078C46.9284 64.896 47.8997 63.9373 47.8997 62.7788V56.5736H15.1392Z" fill="#262526"/>
        <path d="M17.2788 7.18511C15.5725 7.18511 14.1813 8.54333 14.1813 10.2078V62.7656C14.1813 64.43 15.5725 65.7883 17.2788 65.7883H45.6949C47.4275 65.7883 48.8319 64.43 48.8319 62.7656V10.2211C48.8319 8.55665 47.4275 7.19843 45.6949 7.19843H17.2788V7.18511ZM45.7081 66.9867H17.2788C14.9163 66.9867 13 65.0958 13 62.7656V10.2211C13 7.89085 14.9163 6 17.2788 6H45.6949C48.0706 6 50 7.89085 50 10.2211V62.7789C50.0131 65.0958 48.0706 66.9867 45.7081 66.9867Z" fill="#262526"/>
        <path d="M47.9128 17.3716H15.1392V55.6813H47.9128V17.3716Z" fill="#262526"/>
        <path d="M22.8565 35.2948H23.0927C32.7267 35.1217 33.7635 30.1948 33.7635 28.0776C33.7635 26.2133 33.0285 24.6554 31.6766 23.6833C30.6529 22.951 29.3666 22.5914 27.6603 22.5648H22.8434V35.2948H22.8565ZM32.3591 48.1845V47.7984C33.1335 47.5454 33.2648 46.9595 33.291 46.52V46.4801C33.291 46.4535 33.291 46.4135 33.291 46.3869V46.2937C33.291 45.761 33.1335 44.283 33.1335 44.283C32.7135 41.1937 31.401 37.9979 29.721 35.9339L29.6291 35.8141L29.4847 35.854C27.5684 36.36 25.3503 36.6397 23.1059 36.6397H22.8696L22.8827 45.6279C22.8827 46.7597 23.3552 47.5054 24.2478 47.7984V48.1978H18.7745V47.7984C19.6408 47.5187 20.0871 46.813 20.1396 45.6412V45.6279C20.1396 45.4148 20.1396 23.8697 20.1396 23.6167C20.1133 22.3784 19.6671 21.6726 18.7483 21.393V21.0068H27.8309C30.4954 21.0068 32.2279 21.5927 33.6192 22.5382C35.3911 23.7632 36.6248 25.947 36.5986 28.0642C36.5986 30.9405 34.9973 33.3506 32.0966 34.8553C32.0441 34.882 31.9916 34.9086 31.9523 34.9219C31.8998 34.9485 31.8473 34.9752 31.7816 35.0018L31.506 35.135L31.7029 35.3747C33.5929 37.5718 34.7742 40.701 35.3123 42.3788C35.5354 43.0845 35.7192 43.8036 36.0342 45.1219L36.0998 45.3882C36.533 47.1459 37.5174 47.6652 38.0161 47.8117V48.1978L32.3591 48.1845Z" fill="white"/>
        <path d="M26.4396 27.4787C26.4396 27.5719 26.4396 30.3949 26.4396 33.857C27.7653 33.6439 28.7496 33.2178 29.1828 32.9915V26.4267H32.4378C32.1885 25.6411 31.7028 25.0685 31.4928 24.8555H25.0483V25.2416C25.9802 25.5346 26.4265 26.2536 26.4396 27.4787Z" fill="white"/>
        <path d="M29.1958 49.4894L29.1827 40.5012H29.419C29.9309 40.5012 30.6134 40.4879 31.1253 40.4613C30.994 39.9419 30.7709 39.4493 30.6002 39.143C30.2852 39.1563 29.7602 39.1696 29.4321 39.1829H29.1958V37.0125C28.5658 37.1989 27.1089 37.4119 26.4527 37.4918C26.4527 43.2176 26.4527 49.3829 26.4527 49.4894V49.5027C26.4002 50.6745 25.9539 51.3803 25.0876 51.6599V52.0594H30.5609V51.6599C29.6684 51.367 29.1958 50.6213 29.1958 49.4894Z" fill="white"/>
        <path d="M42.3996 49.2497L42.334 48.9833C42.019 47.6651 41.8484 46.9327 41.6121 46.2403C41.074 44.5758 39.8927 41.4466 38.0027 39.2361L37.8058 38.9964L38.0814 38.8633C38.1471 38.8367 38.1996 38.81 38.2521 38.7834C38.3046 38.7568 38.3439 38.7434 38.3964 38.7168C41.2971 37.2121 42.8984 34.8019 42.8984 31.9257C42.9246 29.8085 41.6909 27.6247 39.919 26.3996C39.1577 25.8803 38.1602 25.361 37.0445 25.1613C37.3858 25.7871 37.6352 26.8657 37.6877 27.3584C37.7927 27.425 37.8977 27.4915 38.0027 27.5581C39.3414 28.5302 40.0896 30.0881 40.0896 31.9523C40.0896 33.5636 39.4464 36.826 34.7607 38.4105C35.0626 38.9432 35.312 39.4891 35.4433 39.7954C35.5089 39.7821 35.732 39.7288 35.7976 39.7155L35.942 39.6756L36.0339 39.7954C37.7139 41.8594 39.0264 45.0552 39.4464 48.1444C39.4464 48.1578 39.6039 49.6225 39.6039 50.1551V50.2484C39.6039 50.2883 39.6039 50.3149 39.6039 50.3416V50.3815C39.5777 50.8076 39.4596 51.3935 38.6721 51.6598V52.046H44.3159V51.6731C43.8303 51.5267 42.8328 50.994 42.3996 49.2497Z" fill="white"/>
        <path d="M18.8141 60.6348H19.3129C20.0085 60.6348 20.3497 60.3552 20.3497 59.8092C20.3497 59.5429 20.2579 59.3432 20.1004 59.21C19.9297 59.0769 19.6804 59.0103 19.3522 59.0103H18.8272L18.8141 60.6348ZM17.856 58.1847H19.4966C20.0741 58.1847 20.5335 58.3312 20.8616 58.6241C21.1898 58.9171 21.3604 59.3032 21.3604 59.8092C21.3604 60.1155 21.2816 60.3818 21.1373 60.6215C20.9929 60.8612 20.7829 61.0609 20.5073 61.2074L21.8591 63.298H20.7173L19.641 61.4604C19.5622 61.4604 19.4966 61.4737 19.4572 61.4737C19.4047 61.4737 19.3522 61.4737 19.3129 61.4737H18.8272V63.298H17.856V58.1847Z" fill="white"/>
        <path d="M18.8141 60.6348H19.3129C20.0085 60.6348 20.3497 60.3552 20.3497 59.8092C20.3497 59.5429 20.2579 59.3432 20.1004 59.21C19.9297 59.0769 19.6804 59.0103 19.3522 59.0103H18.8272L18.8141 60.6348ZM17.856 58.1847H19.4966C20.0741 58.1847 20.5335 58.3312 20.8616 58.6241C21.1898 58.9171 21.3604 59.3032 21.3604 59.8092C21.3604 60.1155 21.2816 60.3818 21.1373 60.6215C20.9929 60.8612 20.7829 61.0609 20.5073 61.2074L21.8591 63.298H20.7173L19.641 61.4604C19.5622 61.4604 19.4966 61.4737 19.4572 61.4737C19.4047 61.4737 19.3522 61.4737 19.3129 61.4737H18.8272V63.298H17.856V58.1847Z" stroke="white" stroke-width="0.044" stroke-miterlimit="10"/>
        <path d="M25.8638 62.4729C26.1656 62.4729 26.4413 62.4063 26.6775 62.2598C26.9269 62.1133 27.1106 61.9136 27.255 61.6473C27.3994 61.3809 27.465 61.0747 27.465 60.7285C27.465 60.4089 27.3994 60.1159 27.2682 59.8496C27.1369 59.5833 26.94 59.3703 26.7038 59.2238C26.4544 59.064 26.1788 58.9841 25.8638 58.9841C25.5619 58.9841 25.2994 59.0507 25.05 59.1971C24.8137 59.3436 24.6169 59.5434 24.4725 59.8097C24.3281 60.076 24.2625 60.3823 24.2625 60.7285C24.2625 61.0481 24.3281 61.341 24.4594 61.6073C24.5906 61.8736 24.7744 62.0867 25.0237 62.2332C25.26 62.393 25.5487 62.4729 25.8638 62.4729ZM25.8638 63.3916C25.3781 63.3916 24.9319 63.2851 24.5381 63.0588C24.1312 62.8324 23.8162 62.5261 23.58 62.1133C23.3437 61.7138 23.2256 61.2478 23.2256 60.7285C23.2256 60.2491 23.3306 59.8097 23.5537 59.4102C23.7768 59.0107 24.0918 58.6778 24.4987 58.4381C24.9056 58.1985 25.365 58.0786 25.8769 58.0786C26.3625 58.0786 26.8088 58.1851 27.2157 58.4115C27.6225 58.6379 27.9375 58.9441 28.1738 59.3569C28.41 59.7564 28.5282 60.2225 28.5282 60.7418C28.5282 61.2212 28.4232 61.6606 28.2 62.0601C27.9769 62.4595 27.6619 62.7924 27.255 63.0321C26.835 63.2585 26.3756 63.3916 25.8638 63.3916Z" fill="white"/>
        <path d="M25.8638 62.4729C26.1656 62.4729 26.4413 62.4063 26.6775 62.2598C26.9269 62.1133 27.1106 61.9136 27.255 61.6473C27.3994 61.3809 27.465 61.0747 27.465 60.7285C27.465 60.4089 27.3994 60.1159 27.2682 59.8496C27.1369 59.5833 26.94 59.3703 26.7038 59.2238C26.4544 59.064 26.1788 58.9841 25.8638 58.9841C25.5619 58.9841 25.2994 59.0507 25.05 59.1971C24.8137 59.3436 24.6169 59.5434 24.4725 59.8097C24.3281 60.076 24.2625 60.3823 24.2625 60.7285C24.2625 61.0481 24.3281 61.341 24.4594 61.6073C24.5906 61.8736 24.7744 62.0867 25.0237 62.2332C25.26 62.393 25.5487 62.4729 25.8638 62.4729ZM25.8638 63.3916C25.3781 63.3916 24.9319 63.2851 24.5381 63.0588C24.1312 62.8324 23.8162 62.5261 23.58 62.1133C23.3437 61.7138 23.2256 61.2478 23.2256 60.7285C23.2256 60.2491 23.3306 59.8097 23.5537 59.4102C23.7768 59.0107 24.0918 58.6778 24.4987 58.4381C24.9056 58.1985 25.365 58.0786 25.8769 58.0786C26.3625 58.0786 26.8088 58.1851 27.2157 58.4115C27.6225 58.6379 27.9375 58.9441 28.1738 59.3569C28.41 59.7564 28.5282 60.2225 28.5282 60.7418C28.5282 61.2212 28.4232 61.6606 28.2 62.0601C27.9769 62.4595 27.6619 62.7924 27.255 63.0321C26.835 63.2585 26.3756 63.3916 25.8638 63.3916Z" stroke="white" stroke-width="0.044" stroke-miterlimit="10"/>
        <path d="M31.4937 61.1541L29.6431 58.1847H30.7718L32.0187 60.3552L33.2788 58.1847H34.3681L32.5044 61.1541V63.2714H31.4937V61.1541Z" fill="white"/>
        <path d="M31.4937 61.1541L29.6431 58.1847H30.7718L32.0187 60.3552L33.2788 58.1847H34.3681L32.5044 61.1541V63.2714H31.4937V61.1541Z" stroke="white" stroke-width="0.044" stroke-miterlimit="10"/>
        <path d="M38.1739 63.3512C37.6489 63.3512 37.1896 63.2446 36.7958 63.0449C36.3889 62.8319 36.0739 62.5389 35.8508 62.1394C35.6276 61.74 35.5095 61.2872 35.5095 60.7546C35.5095 60.2486 35.6145 59.8092 35.8377 59.4097C36.0608 59.0102 36.3758 58.6906 36.7958 58.4643C37.2158 58.2379 37.6883 58.118 38.2264 58.118C38.5021 58.118 38.7777 58.158 39.0665 58.2246C39.3552 58.3045 39.5915 58.3977 39.8015 58.5175L39.6308 59.383C39.1452 59.1434 38.6858 59.0235 38.2396 59.0235C37.9114 59.0235 37.6227 59.0901 37.3602 59.2366C37.0977 59.3697 36.9008 59.5695 36.7564 59.8358C36.612 60.0888 36.5333 60.3951 36.5333 60.7546C36.5333 61.2739 36.6908 61.6867 36.9927 61.993C37.3077 62.2992 37.7146 62.459 38.2396 62.459C38.4627 62.459 38.7121 62.4191 38.9746 62.3525C39.2371 62.2859 39.4733 62.1927 39.7096 62.0729L39.8146 62.965C39.5915 63.0982 39.329 63.1914 39.0402 63.2713C38.7646 63.3112 38.4627 63.3512 38.1739 63.3512Z" fill="white"/>
        <path d="M38.1739 63.3512C37.6489 63.3512 37.1896 63.2446 36.7958 63.0449C36.3889 62.8319 36.0739 62.5389 35.8508 62.1394C35.6276 61.74 35.5095 61.2872 35.5095 60.7546C35.5095 60.2486 35.6145 59.8092 35.8377 59.4097C36.0608 59.0102 36.3758 58.6906 36.7958 58.4643C37.2158 58.2379 37.6883 58.118 38.2264 58.118C38.5021 58.118 38.7777 58.158 39.0665 58.2246C39.3552 58.3045 39.5915 58.3977 39.8015 58.5175L39.6308 59.383C39.1452 59.1434 38.6858 59.0235 38.2396 59.0235C37.9114 59.0235 37.6227 59.0901 37.3602 59.2366C37.0977 59.3697 36.9008 59.5695 36.7564 59.8358C36.612 60.0888 36.5333 60.3951 36.5333 60.7546C36.5333 61.2739 36.6908 61.6867 36.9927 61.993C37.3077 62.2992 37.7146 62.459 38.2396 62.459C38.4627 62.459 38.7121 62.4191 38.9746 62.3525C39.2371 62.2859 39.4733 62.1927 39.7096 62.0729L39.8146 62.965C39.5915 63.0982 39.329 63.1914 39.0402 63.2713C38.7646 63.3112 38.4627 63.3512 38.1739 63.3512Z" stroke="white" stroke-width="0.044" stroke-miterlimit="10"/>
        <path d="M41.7708 58.1847H45.1046V59.0369H42.7551V60.262H44.8289V61.1142H42.7551V62.4191H45.1964V63.2714H41.7708V58.1847Z" fill="white"/>
        <path d="M41.7708 58.1847H45.1046V59.0369H42.7551V60.262H44.8289V61.1142H42.7551V62.4191H45.1964V63.2714H41.7708V58.1847Z" stroke="white" stroke-width="0.044" stroke-miterlimit="10"/>
        <path d="M19.1292 12.272H19.628C20.3236 12.272 20.6649 11.9924 20.6649 11.4464C20.6649 11.1801 20.5861 10.9804 20.4155 10.8472C20.2449 10.7141 19.9955 10.6475 19.6674 10.6475H19.1424L19.1292 12.272ZM18.158 9.80859H19.7986C20.3761 9.80859 20.8355 9.95507 21.1636 10.2347C21.4918 10.5277 21.6624 10.9138 21.6624 11.4198C21.6624 11.7261 21.5836 11.9924 21.4393 12.2321C21.2949 12.4718 21.0849 12.6715 20.8093 12.818L22.1612 14.9086H21.0193L19.943 13.071C19.8642 13.071 19.7986 13.0843 19.7592 13.0843C19.7067 13.0843 19.6542 13.0843 19.6149 13.0843H19.1292V14.9086H18.158V9.80859Z" fill="white"/>
        <path d="M19.1292 12.272H19.628C20.3236 12.272 20.6649 11.9924 20.6649 11.4464C20.6649 11.1801 20.5861 10.9804 20.4155 10.8472C20.2449 10.7141 19.9955 10.6475 19.6674 10.6475H19.1424L19.1292 12.272ZM18.158 9.80859H19.7986C20.3761 9.80859 20.8355 9.95507 21.1636 10.2347C21.4918 10.5277 21.6624 10.9138 21.6624 11.4198C21.6624 11.7261 21.5836 11.9924 21.4393 12.2321C21.2949 12.4718 21.0849 12.6715 20.8093 12.818L22.1612 14.9086H21.0193L19.943 13.071C19.8642 13.071 19.7986 13.0843 19.7592 13.0843C19.7067 13.0843 19.6542 13.0843 19.6149 13.0843H19.1292V14.9086H18.158V9.80859Z" stroke="white" stroke-width="0.044" stroke-miterlimit="10"/>
        <path d="M26.1643 14.1095C26.4662 14.1095 26.7418 14.0429 26.9781 13.8964C27.2274 13.7499 27.4112 13.5502 27.5556 13.2839C27.6999 13.0176 27.7656 12.7113 27.7656 12.3651C27.7656 12.0455 27.6999 11.7525 27.5687 11.4862C27.4374 11.2199 27.2406 11.0069 27.0043 10.8604C26.7549 10.7006 26.4793 10.6207 26.1643 10.6207C25.8624 10.6207 25.5999 10.6873 25.3505 10.8338C25.1143 10.9802 24.9174 11.18 24.773 11.4463C24.6286 11.7126 24.563 12.0189 24.563 12.3651C24.563 12.6847 24.6286 12.9776 24.7599 13.2439C24.8911 13.5102 25.0749 13.7233 25.3243 13.8698C25.5737 14.0296 25.8493 14.1095 26.1643 14.1095ZM26.1643 15.0149C25.6787 15.0149 25.2324 14.9084 24.8386 14.682C24.4318 14.4557 24.1168 14.1494 23.8805 13.7366C23.6443 13.3371 23.5261 12.8711 23.5261 12.3518C23.5261 11.8724 23.6311 11.433 23.8543 11.0335C24.0774 10.634 24.3924 10.3144 24.7993 10.0614C25.2062 9.82175 25.6655 9.7019 26.1774 9.7019C26.6631 9.7019 27.1093 9.80843 27.5162 10.0348C27.9231 10.2612 28.2381 10.5674 28.4743 10.9802C28.7106 11.3797 28.8287 11.8458 28.8287 12.3651C28.8287 12.8445 28.7237 13.2839 28.5006 13.6834C28.2775 14.0828 27.9624 14.4157 27.5556 14.6554C27.1356 14.8951 26.6762 15.0149 26.1643 15.0149Z" fill="white"/>
        <path d="M26.1643 14.1095C26.4662 14.1095 26.7418 14.0429 26.9781 13.8964C27.2274 13.7499 27.4112 13.5502 27.5556 13.2839C27.6999 13.0176 27.7656 12.7113 27.7656 12.3651C27.7656 12.0455 27.6999 11.7525 27.5687 11.4862C27.4374 11.2199 27.2406 11.0069 27.0043 10.8604C26.7549 10.7006 26.4793 10.6207 26.1643 10.6207C25.8624 10.6207 25.5999 10.6873 25.3505 10.8338C25.1143 10.9802 24.9174 11.18 24.773 11.4463C24.6286 11.7126 24.563 12.0189 24.563 12.3651C24.563 12.6847 24.6286 12.9776 24.7599 13.2439C24.8911 13.5102 25.0749 13.7233 25.3243 13.8698C25.5737 14.0296 25.8493 14.1095 26.1643 14.1095ZM26.1643 15.0149C25.6787 15.0149 25.2324 14.9084 24.8386 14.682C24.4318 14.4557 24.1168 14.1494 23.8805 13.7366C23.6443 13.3371 23.5261 12.8711 23.5261 12.3518C23.5261 11.8724 23.6311 11.433 23.8543 11.0335C24.0774 10.634 24.3924 10.3144 24.7993 10.0614C25.2062 9.82175 25.6655 9.7019 26.1774 9.7019C26.6631 9.7019 27.1093 9.80843 27.5162 10.0348C27.9231 10.2612 28.2381 10.5674 28.4743 10.9802C28.7106 11.3797 28.8287 11.8458 28.8287 12.3651C28.8287 12.8445 28.7237 13.2839 28.5006 13.6834C28.2775 14.0828 27.9624 14.4157 27.5556 14.6554C27.1356 14.8951 26.6762 15.0149 26.1643 15.0149Z" stroke="white" stroke-width="0.044" stroke-miterlimit="10"/>
        <path d="M30.7849 9.80859H31.7956V14.0697H34.0793V14.9086H30.7849V9.80859Z" fill="white"/>
        <path d="M30.7849 9.80859H31.7956V14.0697H34.0793V14.9086H30.7849V9.80859Z" stroke="white" stroke-width="0.044" stroke-miterlimit="10"/>
        <path d="M36.0732 9.80859H37.0839V14.0697H39.3677V14.9086H36.0732V9.80859Z" fill="white"/>
        <path d="M36.0732 9.80859H37.0839V14.0697H39.3677V14.9086H36.0732V9.80859Z" stroke="white" stroke-width="0.044" stroke-miterlimit="10"/>
        <path d="M42.834 15.0149C42.519 15.0149 42.204 14.975 41.889 14.8951C41.574 14.8152 41.3246 14.7087 41.1409 14.5888L41.2459 13.71C41.4559 13.8432 41.7053 13.963 41.994 14.0429C42.2828 14.1228 42.5453 14.1627 42.8078 14.1627C43.1097 14.1627 43.3459 14.1095 43.5165 13.9896C43.6872 13.8698 43.7659 13.71 43.7659 13.5103C43.7659 13.3371 43.7003 13.1907 43.5559 13.0841C43.4115 12.9776 43.149 12.8578 42.7684 12.7379C42.2171 12.5648 41.8365 12.3518 41.6003 12.1121C41.364 11.8724 41.2459 11.5528 41.2459 11.1533C41.2459 10.8604 41.3115 10.6207 41.4559 10.3943C41.6003 10.1813 41.8103 10.0082 42.0859 9.87502C42.3615 9.75518 42.6765 9.6886 43.044 9.6886C43.3065 9.6886 43.569 9.72855 43.8447 9.79513C44.1203 9.8617 44.3565 9.95492 44.5534 10.0748L44.409 10.927C44.2253 10.8204 44.0022 10.7272 43.7659 10.6607C43.5297 10.5941 43.2934 10.5541 43.0834 10.5541C42.7946 10.5541 42.5846 10.6074 42.4403 10.7139C42.2959 10.8204 42.2303 10.9669 42.2303 11.1533C42.2303 11.2732 42.2565 11.3797 42.309 11.4463C42.3615 11.5262 42.4534 11.5928 42.5846 11.6593C42.7159 11.7259 42.8865 11.7925 43.1228 11.8724C43.7265 12.0588 44.1465 12.2852 44.3959 12.5382C44.6453 12.7912 44.7766 13.1108 44.7766 13.5103C44.7766 13.9763 44.6059 14.3492 44.2647 14.6155C43.8972 14.8818 43.4247 15.0149 42.834 15.0149Z" fill="white"/>
        <path d="M42.834 15.0149C42.519 15.0149 42.204 14.975 41.889 14.8951C41.574 14.8152 41.3246 14.7087 41.1409 14.5888L41.2459 13.71C41.4559 13.8432 41.7053 13.963 41.994 14.0429C42.2828 14.1228 42.5453 14.1627 42.8078 14.1627C43.1097 14.1627 43.3459 14.1095 43.5165 13.9896C43.6872 13.8698 43.7659 13.71 43.7659 13.5103C43.7659 13.3371 43.7003 13.1907 43.5559 13.0841C43.4115 12.9776 43.149 12.8578 42.7684 12.7379C42.2171 12.5648 41.8365 12.3518 41.6003 12.1121C41.364 11.8724 41.2459 11.5528 41.2459 11.1533C41.2459 10.8604 41.3115 10.6207 41.4559 10.3943C41.6003 10.1813 41.8103 10.0082 42.0859 9.87502C42.3615 9.75518 42.6765 9.6886 43.044 9.6886C43.3065 9.6886 43.569 9.72855 43.8447 9.79513C44.1203 9.8617 44.3565 9.95492 44.5534 10.0748L44.409 10.927C44.2253 10.8204 44.0022 10.7272 43.7659 10.6607C43.5297 10.5941 43.2934 10.5541 43.0834 10.5541C42.7946 10.5541 42.5846 10.6074 42.4403 10.7139C42.2959 10.8204 42.2303 10.9669 42.2303 11.1533C42.2303 11.2732 42.2565 11.3797 42.309 11.4463C42.3615 11.5262 42.4534 11.5928 42.5846 11.6593C42.7159 11.7259 42.8865 11.7925 43.1228 11.8724C43.7265 12.0588 44.1465 12.2852 44.3959 12.5382C44.6453 12.7912 44.7766 13.1108 44.7766 13.5103C44.7766 13.9763 44.6059 14.3492 44.2647 14.6155C43.8972 14.8818 43.4247 15.0149 42.834 15.0149Z" stroke="white" stroke-width="0.044" stroke-miterlimit="10"/>
        </g>
        <defs>
        <clipPath id="clip0_906_5710">
        <rect width="37" height="61" fill="white" transform="translate(13 6)"/>
        </clipPath>
        </defs>
        </svg>
        
        


      
    )
  }

  
  export default LogoRollsRoyce